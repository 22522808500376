@font-face {
    font-family: "Poppins Inter", sans-serif;
    src: url("../../fonts/PoppinsInter/Poppins-Regular.ttf");
}

.content-card-color,.content-card-color-small {
    margin: 10px;
    width: 280px;
    height: 230px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}
.content-card-color-bloqued{
    margin: 10px;
    width: 280px;
    height: 230px;
    border-radius: 8px;
    cursor:not-allowed;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.content-card-color-small{
    width: 280px;
    height: 180px;
}

.content-card-color:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.2);
}

.header-card-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 80px;
    position: relative;
}

.text-card-color-title {
    position: absolute;
    font-family: "Poppins Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.avatar-color-card {
    position: absolute;
    bottom: -38px;
}

.content-color-card, .center-desc{
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

}
.center-desc{
    justify-content: center;
    align-items: center;
    display: flex;
}

.text-description-card-color {
    font-family: "Poppins Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #6E7787FF
}


.ul-list-description-card {
    margin-top: 1px;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
}

.list-description-card-color {
    display: flex;
    align-items: center;
}

.foter-description-card {
    margin-bottom: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

}
.list-description-colum{
    display: flex;
    flex-direction: column;
    padding: 5px;
}