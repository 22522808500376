.login-box {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center
}

.loginAvatar {
    margin: 1px;
    background-color: #e857df !important;
}