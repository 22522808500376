@font-face {
   font-family: "Poppins Inter", sans-serif;
   src: url('./../../fonts/PoppinsInter/Poppins-Medium.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
}

.box-dashboard {
   display: flex;
   margin: 0;
   font-family: "Poppins Inter", sans-serif;
}

.toolbar-dashboard {
   background-color: #F3F4F9;
   display: flex;
   align-items: center;
   flex-wrap: nowrap;
   overflow: hidden;
   z-index: 1;
   border: none;

}

.logo-dashboard {
   width: 200px;
   height: auto;
}

.boxlist {
   font-family: "Poppins Inter", sans-serif !important;
   border: 1px solid #eeedf2;
   filter: drop-shadow(0px 0px 4px #F8F8F9);
   height: 100%;
}

.listItem-dashboard {
   height: 40px;
   margin-bottom: 1.5rem;
   margin-left: 0;
   margin-right: 0;
   padding: 0;
   overflow: hidden;
   color: #565E6C;
}

.custom-list-item {
   box-sizing: border-box;
   padding-left: 2.7px;
   padding-right: 2.7px;
   padding-top: 5px;
   padding-bottom: 0px;
   border-radius: 8px;
   min-height: 30px;
   display: flex;
   justify-content: left;
   box-shadow: 0 0 10px 0 #F8F8F9;
   border: 1px solid #eeedf2;

}

.custom-list-item:hover {
   background-color: #ebf0fe;
}

.custom-list-item.Mui-selected {
   background-color: #F5F2FD;
   color: #8353E2;
   font-weight: bold;
}

.custom-list-item.Mui-selected:hover {
   background-color: #ebf0fe;
}

.custom-list-item .custom-icon {
   margin-right: 8px;
}

.custom-list-item.Mui-selected .custom-icon {
   color: #8353E2;
}

.boxContainer-dashboard {
   background-color: white;
   flex-grow: 1;
   height: 100vh;
   overflow: auto;
   padding-top: 10px;
}

.container-dashboard {
   padding-top: 1rem;
   padding-bottom: 1rem;
}

.flag_es {
   width: 30px;
   height: auto;
}

.navBar {
   background-color: #F3F4F9 !important;
}

.firstToolbar {
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.navToolbar {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 1px;
   margin: 0;
}

.boxDivider {
   padding-left: 1rem;
   padding-right: 1rem;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   border-left: solid 1px #DEE1E6;

}

.userNavTex {
   color: #171A1F
}

.iconUser {
   color: #9095A0
}