@font-face {
    font-family: "Poppins", sans-serif;
    src: url('./../../fonts/Poppins/Poppins-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins Inter", sans-serif;
    src: url('./../../fonts/PoppinsInter/Poppins-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins Inter Bold", sans-serif;
    src: url('./../../fonts/PoppinsInter/Poppins-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.divHeaderButtons-users {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.titleHeader-users {
    display: flex;
    align-items: center;
}

.h1-users {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: #323842;
    margin: 0;
    padding: 0;
    
}

.divider-users {
    border: 1px solid #eeedf2;
}

.divButtons-users {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px
}

.divCount-users {
    padding: 2;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #777a7e;
    margin-top: 1rem;
}

.pcount-users {
    color: #9095A0;
    flex-grow: 1;

}

.spanCount-users {
    font-weight: bold;
    color: #171A1F;
}

.customPagination .MuiPaginationItem-page {
    background-color: #F5F2FD;
    color: #424955;
}

.customPagination .MuiPaginationItem-page.Mui-selected {
    background-color: #F5F2FD;
    color: #8353E2;
}

.boxTabsContext-users {
    border-bottom-width: 0.3px;
    border-bottom-style: solid;
    border-bottom-color: #d6dfee;
}

.tabUsers {
    text-transform: none;
    font-weight: bold;
}

.divpag-users {
    align-items: center;
    margin-top: 15;
    display: flex;
}

.usernameTable {
    display: flex;
    align-items: center;
}

.avatarTable {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    background-color: #8353e2;
}

.custom-checkbox {
    display: flex;
    justify-content: center;
}

.custom-checkbox .MuiSvgIcon-root {
    fill: #424955;
}

.custom-checkbox .MuiCheckbox-icon {
    fill: #FFFFFF;
}

.editIcon {
    color: #9095A0;
}

.backPreviews-user {
    font-family: "Poppins Inter", sans-serif;
    color: #8353E2;
    margin: 0;
    padding: 0;
}

.dividerDetail-user {
    border: 1px solid #F3F4F6;
}

.divAvatar-user {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.divName-user {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 16px;
    padding: 0;
}

.name-user {
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    color: #323842;
}

.email-user {
    padding: 0;
    margin: 0;
    font-family: "Poppins Inter", sans-serif;
    color: #6E7787;
    font-size: small;
}

.avatarDetail-user {
    background-color: #8A46EA;
    color: #FFFFFF;
    width: 56px;
    height: 56px;
}

.headerTab-user {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

.avatarTab-user {
    width: 35px;
    height: 35px;
    background-color: #4C79FF;
    color: #FFFFFF;
}

.pTab-user {
    font-family: "Poppins", sans-serif;
    margin-left: 8px;
    color: #171A1F;
}

.tabContainer-user {
    padding-left: 0.19in;
    padding-top: 0;
    margin-top: 0;
    font-family: 'Inter';
    font-size: 12px;
}

.infoG-user {
    font-family: "Poppins Inter", sans-serif;
    font-size: 12px;
}

.infoGTitle-user {
    margin: 0;
    font-size: 14px;
    font-family: "Poppins Inter Bold", sans-serif;
    color: #323842;
    font-weight: 900;
}

.infoMembership-user {
    padding: 0.22in;
    padding-top: 0
}

.infoMembership-user .grid {
    margin: 0px;
    margin-top: 0;
    border: 1px solid #f2f2f2;
    padding: 0.2in;
}

.infoMembership-user .grid .item2 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.infoPass-user {
    font-family: "Poppins Inter", sans-serif;
    font-size: 14px;
    color: #171A1F;
}

.infoPassDisclaimer-user {
    font-family: "Poppins Inter", sans-serif;
    font-size: 14px;
    color: #9095A0;
}

.infoPassInput-user {
    margin: 16px;
    font-size: 12px;
}
.assign-muser{
    display: flex;
    justify-content: center;
    padding: 2;
}
.div-assign-muser{
    display: flex;
    justify-content: center; 
    flex-direction: column;
}
.div-multi-muser{
    display: flex;
    justify-content: flex-start;
    padding: 2;
}
.select-assign{
padding-bottom: 2;
}
.div-dates-muser{
    flex-direction: row;
    display: flex; 
    margin-top: 5px; 
    padding: 4
}
.div-start{
    margin-right: 10px;
}

.div-add-muser{
    padding: 0;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.spanSelected-users{
    font-weight: bold;
    color: #171A1F;
    margin-right: 10px;
    margin-left: 10px;
}
.textField .MuiTextField-root {
    width: 25ch;
    height: 10ch;
    padding: 0;
}

.span_missing{
    font-style: italic;
    font-family: "Poppins Inter", sans-serif;
}
.h1_warning{
    font-family: "Poppins Inter", sans-serif;
    align-items: center;
    display: flex;
    font-weight: bold;
    color: #323842;
    margin-left: 15px;
    margin-top: 0;
    padding: 0;
}